// Dependencies
import React, { useEffect } from "react";
import { AppProps } from "next/app";
import TagManager from "react-gtm-module";

import { AnalyticsGateway } from "@components/analytics/AnalyticsGateway";
import { BluesErrorBoundary } from "@components/BluesErrorBoundary";
import { CAuthContextProvider } from "@configured/adapters/CAuthContextProvider";
import { ClientSideLinkRouting } from "@components/nextjs/ClientSideLinkRouting";
import { CMonitoring } from "@configured/CMonitoring";
import { Config } from "@configured/EnvironmentConfig";
import { RouteTracker } from "@components/analytics/RouteTracker";
import { SettingsProvider } from "@components/settings/SettingsProvider";
import { TabSelector } from "@components/doc/TabSelector";
import Layout from "@components/layout/Layout";
import NextJSQueryHighlighter from "@components/search/NextJSQueryHighlighter";

CMonitoring();

function MyApp({ Component, pageProps }: AppProps) {
  // Google Tag Manager needs this to be able to track page changes on GTM:
  // https://www.learnbestcoding.com/post/9/easiest-way-to-integrate-google-analytics-with-react-js-and-next-js
  useEffect(() => {
    if (Config.googleTagManagerID) {
      TagManager.initialize({
        gtmId: Config.googleTagManagerID,
      });
    }
  }, []);

  // tracks page context when user initially lands on page on site whether through direct URL or typical navigation (works for accelerator pages, notecard playground, community pages with filters, etc.)
  useEffect(() => {
    AnalyticsGateway().trackPageContext({});
  }, []);

  return (
    <React.StrictMode>
      <BluesErrorBoundary boundaryName="App">
        <NextJSQueryHighlighter secondsAfterPageChange={2} />
        <SettingsProvider>
          <ClientSideLinkRouting>
            <TabSelector>
              <CAuthContextProvider>
                <Layout>
                  <RouteTracker />
                  <Component {...pageProps} />
                </Layout>
              </CAuthContextProvider>
            </TabSelector>
          </ClientSideLinkRouting>
        </SettingsProvider>
      </BluesErrorBoundary>
    </React.StrictMode>
  );
}

export default MyApp;
