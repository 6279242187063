import React, { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import theme from "@config/theme";
import { useRouter } from "next/router";
import HighlightGlobally from "./HighlightGlobally";

interface HighlighterProps {
  secondsAfterPageChange: number;
}

const NextJSQueryHighlighter: React.FC<HighlighterProps> = (
  { secondsAfterPageChange } = { secondsAfterPageChange: 2 },
) => {
  const searchParams = useSearchParams();
  const { pathname } = useRouter();
  const [words, setWords] = useState<string[]>([]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      const wordsParam = searchParams.get("highlight");
      const newWords = wordsParam ? wordsParam.split(",") : [];
      setWords(newWords);
    }, secondsAfterPageChange * 1000);
    return () => clearTimeout(timeout); // cleanup on next render
  }, [pathname, searchParams, secondsAfterPageChange]);
  if (!words || !words.length) return null;
  return (
    <HighlightGlobally
      words={words}
      textColor={theme.colors.ultramarine}
      backgroundColor="#FFF8"
    />
  );
};

export default NextJSQueryHighlighter;
