/* eslint-disable-next-line import/no-extraneous-dependencies */
import css from "styled-jsx/css";
import { ImgSrc } from "@config/image";
import theme from "@config/theme";
import { barlow, firaCode, materialIcons } from "@styles/fonts";

export default css.global`
  * {
    box-sizing: border-box;
  }

  :root {
    --page-header-height: calc(1 * ${theme.header.height});
    --search-padding: 0.5rem;
    --search-button-height: calc(
      ${theme.header.height} - 2 * var(--search-padding)
    );
    /* react-tooltip styling */
    --tooltip-width: 210px;
    --barlow-font: ${barlow.style.fontFamily};
    --fira-code-font: ${firaCode.style.fontFamily};
    --material-icons-font: ${materialIcons.style.fontFamily};
  }

  html {
    background-color: ${theme.colors.white};
    font-family: var(--barlow-font);
    color: ${theme.colors.black};
    font-size: ${theme.fonts.size.px16};
    line-height: 1.5;
    font-weight: 400;
    height: 100%;
    letter-spacing: ${theme.fonts.letterSpacing.one};
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  body {
    overflow-x: hidden;
  }

  .breadcrumbs + h1 {
    margin-top: 0;
  }
  .breadcrumbs + h1 + p {
    font-size: ${theme.fonts.size.px18};
  }

  /* all table styling */
  .table-container {
    margin: 2rem 0 1rem 0;
    max-height: 80vh;
  }

  table {
    border-spacing: 0;
    border-radius: 4px;
    border: 1px solid ${theme.colors.lightGray};
    width: 100%;
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  table thead {
    display: table-header-group;
    color: ${theme.colors.gray1};
    background: ${theme.colors.lightSnowBlue};
    vertical-align: middle;
    border-color: inherit;
    position: sticky;
    top: 0;
  }

  table thead th {
    vertical-align: bottom;
  }

  table tr td,
  table tr th {
    padding: 0.75rem;
    vertical-align: top;
    border-right: 1px solid ${theme.colors.lightGray};
  }

  @media only screen and (max-width: ${theme.breakpoints.sm}px) {
    table th {
      text-align: left;
      position: revert;
    }
  }

  table tr td {
    border-top: 1px solid ${theme.colors.notehubBlueTint};
  }

  table tr td:last-child,
  table tr th:last-child {
    border-right: none;
  }

  table tbody tr:hover {
    background-color: ${`${theme.colors.lightGray}26`};
  }

  table.compact {
    font-size: ${theme.fonts.size.px14};
  }
  table.compact ul {
    margin-block-start: 0;
    padding-inline-start: 10px;
  }
  table.vertical-center-cells td {
    vertical-align: middle;
  }

  blockquote {
    background-color: ${theme.colors.gray6};
    margin: 1rem 0;
    display: flow-root;
    padding: 0 1rem 0 2rem;
    border-left: 0.5rem solid ${theme.colors.notehubBlueTintLighter};
    border-radius: 4px;
    position: relative;
  }
  blockquote::before {
    content: " ";
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    background-image: url("${ImgSrc("/images/blog/left-quote.svg")}");

    height: 24px;
    width: 24px;
  }

  a:not(.btn, button) {
    text-decoration: none;
    cursor: pointer;
    color: ${theme.colors.ultramarineTint};
  }

  a:not(.btn, button, .menuHeader, .accelerator-link, .article-link):hover {
    color: ${theme.colors.ultramarine};
  }

  button,
  textarea,
  input {
    outline: 0;
  }

  /* remove blue highlight on touch devices */
  input,
  textarea,
  button,
  select,
  div,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-search-cancel-button,
  ::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  select {
    padding: 0.5em 1em;
    border: 1px solid ${theme.colors.lightGray};
    border-radius: 3px;
    background: white;
    font-size: 1rem;
    color: ${theme.colors.black};
  }

  /* primary button */
  .btn {
    border: 2px solid ${theme.colors.ultramarineTint};
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    display: inline-block;
    cursor: pointer;
    background-color: ${theme.colors.ultramarineTint};
    color: ${theme.colors.white};
    text-decoration: none;
  }

  /* primary button turns ultramarine on hover */
  .btn:hover,
  .btn:focus {
    background-color: ${theme.colors.ultramarine};
    border-color: ${theme.colors.ultramarine};
  }

  /* secondary button (outlined button) on white background */
  .btn.btnSecondary {
    color: ${theme.colors.ultramarineTint};
    background-color: transparent;
    border: 2px solid ${theme.colors.ultramarineTint};
  }

  /* secondary button on white background hover and focus */
  .btn.btnSecondary:hover,
  .btn.btnSecondary:focus {
    color: ${theme.colors.ultramarine};
    border-color: ${theme.colors.ultramarine};
  }

  /* secondary button on black background (outlined button) */
  .btn.btnSecondary.dark {
    color: ${theme.colors.white};
    border: 2px solid ${theme.colors.ultramarineTint};
  }

  /* secondary button on black background hover and focus */
  code .btn.btnSecondary.dark:hover,
  .btn.btnSecondary.dark:focus {
    background-color: ${theme.colors.ultramarineTint};
  }

  h1 code,
  h2 code,
  h3 code,
  h4 code,
  h5 code,
  h6 code,
  p code,
  table code,
  li > * > code,
  li > code {
    font-size: 0.875em;
    font-weight: normal;
    font-family: var(--fira-code-font);
    font-style: normal;
    color: ${theme.colors.poppyRed};
    border: 1px solid ${theme.colors.lightGray};
    border-radius: 4px;
    box-shadow: inset 0 0 0 0.5px ${theme.colors.gray5};
    line-height: 1.4;
    white-space: pre-wrap;
    padding: 0.15rem 0.3rem;
    overflow-wrap: break-word;
  }

  pre,
  code,
  input,
  textarea {
    font-variant-ligatures: none;
  }

  pre code {
    font-family: var(--fira-code-font);
  }

  h2:not(:first-of-type),
  .dotted-divider {
    border-top: 1px solid ${theme.colors.gray4};
    padding-top: 3rem;
    margin-top: 3.5rem;
  }

  aside .details p {
    margin-top: 0;
  }

  /* disable animations */
  .noAnimations {
    transition-property: none !important;
  }

  img.with-shadow {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .m-r-1 {
    margin-right: 1rem;
  }

  .button-as-link {
    border: 0;
    color: ${theme.colors.ultramarineTint};
    background: transparent;
  }
  .button-as-link:hover {
    cursor: pointer;
  }

  /* Filter containers used on community projects and accelerators */
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }

  /* Featured card lists used on community projects and accelerators */
  .featured-card-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 285px);
    grid-gap: 1rem;
  }
  .featured-card-list a:hover {
    text-decoration: none;
  }
  .featured-card {
    color: ${theme.colors.black};
    border: 1px solid ${theme.colors.lightGray};
    padding: 1rem;
    border-radius: 10px;
    background: white;
    overflow: hidden;
    height: 100%;
  }
  .featured-card:hover {
    background: ${theme.colors.blue0};
  }
  .featured-card-list img {
    width: 100%;
  }
  .featured-card-list h3 {
    margin-top: 1rem;
  }
  .featured-card-content {
    margin: 1rem 0.5rem 0.5rem 0.5rem;
  }

  @media only screen and (max-width: ${theme.breakpoints.sm}px) {
    .featured-card-list {
      grid-template-columns: 100%;
    }
  }

  /* Tooltip styling reference docs for more styling options https://react-tooltip.com/docs/examples/styling */
  /* Note CSS must be here to override React Tooltip default styles  */
  .tooltip-container {
    position: absolute;
  }
  .tooltip-container .tooltip {
    font-size: ${theme.fonts.size.px12};
    background: ${theme.colors.notehubBlueTint};
    text-align: center;
  }

  .tooltip-container .standard-tooltip {
    z-index: ${theme.zIndex.tooltip};
    width: var(--tooltip-width);
  }

  .tooltip-container .copied-tooltip {
    padding: 2px 4px;
  }

  /* Social share buttons used on the blog */
  .social-share-button {
    background: ${theme.colors.devGray};
    border-radius: 50%;
    border: none;
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .social-share-button:hover {
    background: ${theme.colors.ultramarine};
  }
  .social-share-button svg,
  .social-share-button span {
    fill: ${theme.colors.devGray};
    color: ${theme.colors.ultramarineTint};
  }
  .social-share-button:hover svg,
  .social-share-button:hover span {
    color: ${theme.colors.white};
    fill: ${theme.colors.ultramarine};
  }

  /* for printing pages of the site */
  @media print {
    body {
      margin: 0;
      width: 100% !important;
      color: ${theme.colors.black};
      background: ${theme.colors.white} !important;
      overflow-y: visible;
    }

    .page-layout {
      display: block !important;
    }

    .page-menu,
    .page-header,
    .StyledContainer,
    .page-footer,
    .btnCopy {
      display: none !important;
    }

    aside,
    .codeTabs,
    .codeBlockContainer {
      break-inside: avoid;
    }

    table,
    aside,
    .codeTabs,
    .codeBlockContainer {
      -webkit-print-color-adjust: exact;
    }

    table {
      break-after: auto;
    }

    .table-container {
      max-height: none;
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    td {
      page-break-inside: avoid;
      page-break-after: auto;
    }

    thead {
      display: table-header-group;
    }

    tfoot {
      display: table-footer-group;
    }

    h2:not(:first-of-type) {
      break-before: page;
    }

    .choiceBarContainer {
      border: 1px solid ${theme.colors.lightGray};
    }

    .repl-positioner,
    iframe {
      display: none;
      width: 0 !important;
      height: 0 !important;
      overflow: hidden !important;
      line-height: 0pt !important;
      white-space: nowrap;
    }
  }

  /* Material Icons */
  /* Self hosted to work on Apple Lockdown Mode */
  /* CSS from https://fonts.googleapis.com/icon?family=Material+Icons+Round */
  /* Use like this: <i class="material-icons-round">help</i> */
  .material-icons-round {
    font-family: var(--material-icons-font);
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }
`;
