import React, { useEffect } from "react";

const debug = false;
// eslint-disable-next-line no-console
const debugLog = debug ? console.log : () => {};

interface HighlightProps {
  words?: string[];
  backgroundColor?: string;
  textColor?: string;
}

const HighlightGlobally: React.FC<HighlightProps> = ({
  words,
  backgroundColor,
  textColor,
}) => {
  useEffect(() => {
    const noCleanup = () => {};
    if (!("highlights" in CSS)) {
      debugLog("CSS Highlights API is not supported in this browser.");
      return noCleanup;
    }

    if (!words || !words.length) return noCleanup;

    // Find all text nodes on the page
    const treeWalker = document.createTreeWalker(
      document.body,
      NodeFilter.SHOW_TEXT,
    );

    debugLog(`{words: ${words}}`);

    const textNodes: Text[] = [];
    let currentNode = treeWalker.nextNode();

    while (currentNode) {
      textNodes.push(currentNode as Text);
      currentNode = treeWalker.nextNode();
    }

    debugLog(`count of textNodes: ${textNodes.length}`);

    // Find ranges for all words
    const ranges: Range[] = [];

    textNodes.forEach((node) => {
      const textContent = node.textContent?.toLowerCase() || "";

      words.forEach((word) => {
        const lowerWord = word.toLowerCase();
        let startPos = 0;

        while (startPos < textContent.length) {
          const index = textContent.indexOf(lowerWord, startPos);
          if (index === -1) break;

          const range = new Range();
          range.setStart(node, index);
          range.setEnd(node, index + lowerWord.length);
          ranges.push(range);

          startPos = index + lowerWord.length;
        }
      });
    });

    debugLog(`count of ranges: ${ranges.length}`);

    // Apply highlights
    const highlight = new Highlight(...ranges);
    CSS.highlights.set("search-result-highlight", highlight);
    const cleanup = () => {
      CSS.highlights.delete("search-result-highlight");
    };
    return cleanup;
  }, [words]); // Re-run when words change

  return (
    <>
      <style jsx global>{`
        ::highlight(search-result-highlight) {
          background-color: ${backgroundColor};
          color: ${textColor};
        }
      `}</style>
    </>
  );
};

// set default props
HighlightGlobally.defaultProps = {
  words: [],
  backgroundColor: "yellow",
  textColor: "black",
};

export default HighlightGlobally;
